@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.tooltip {
    position: absolute;
    pointer-events: none;
    color:#fff;
    text-align: left;   
    opacity: 0;
    background-color: #616161;
    border-radius: 0.25rem;
    padding: 10px;
    z-index: 50;
    font-family: "Rubik", sans-serif;
    display: none;
  }
  
.tooltip.show {
    opacity: 1;
    display: block;
  }

.tooltip-container {
    display: inline-block;
    position: relative;
  }
  
.model-tooltip {
    background-color: #616161;
    /* .bg-gray-700 {
        --tw-bg-opacity: 1;
        background-color: rgb(97 97 97 / var(--tw-bg-opacity));
    } 
    */
    padding: 10px;
    pointer-events: none;
    color:#fff;
    text-align: center;
    border-radius: 0.25rem /* 4px */;
    padding: 0rem 0.5rem;   
    position: absolute;
    z-index: 50;
    /* left: 95%; */
    transform: translate(-0%, -94%);
    font-size: 16px;       
    box-sizing: border-box;
    /* top: -5px; */
    font-family: "Rubik", sans-serif;
    text-align: left;
    white-space: nowrap;
    transition: opacity 0.2s ease;
    }

.latent-tooltip {
  background-color: #616161;
  padding: 0rem 0.5rem;
  pointer-events: none;
  color: #fff;
  text-align: left;
  border-radius: 0.25rem; /* 4px */
  position: absolute;
  z-index: 50;
  transform: translate(200, 200);
  font-size: 16px;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  transition: opacity 0.2s ease;
  max-width: 300px; /* Maximum width */
  white-space: normal; /* Allow text to wrap */
}

    /* .model-tooltip {
        position: absolute;
        background-color: #4a5568;
        color: #fff;               
        font-size: 0.875rem;       
        padding: 0.25rem 0.5rem;   
        border-radius: 0.25rem;    
        opacity: 0;                
        pointer-events: none;
        transition: opacity 0.2s ease;
        z-index: 1000;
        white-space: nowrap;
      } 
      /*

      /* .model-tooltip.visible {
        opacity: 1;
      } */

